import React from 'react'

import Page from './Page'
import ResumeView from './ResumeView'

const ResumePage = () => {
  return (
    <Page>
      <ResumeView />
    </Page>
  )
}
export default ResumePage
